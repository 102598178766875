import {Component, OnInit, TemplateRef} from '@angular/core';
import {ApiService} from '../api.service';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {bootstrapApplication} from '@angular/platform-browser';
declare var bootstrap: any;

@Component({
  selector: 'app-request-mass-import-ammia',
  templateUrl: './request-mass-import-ammia.component.html',
  styleUrls: ['./request-mass-import-ammia.component.css']
})
export class RequestMassImportAmmiaComponent implements OnInit {
  dataRequest: any = {};
  clientList: any = [];
  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet'];
  statsend: boolean;
  listRequestTemp: any[];
  modalRef: BsModalRef;
  responseServiceDownloadExcel: number;
  isDelete: boolean;

  // variables para detalle de importación
  requestTempDetail: any[];
  requestTemp: any;
  policies: any[];
  idRequestTemp: string;
  requestsForm: FormGroup;
  // Objetos para los DatePickers ->
  bsBirthdate: Partial<BsDatepickerConfig>;
  bsDateMovement: Partial<BsDatepickerConfig>;
  idInterval: any;
  subranchCategories: [];
  constructor(private api: ApiService,
              private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public shared: SharedService,
              private datePipe: DatePipe,
              private formBuilder: FormBuilder) {
    this.requestsForm = this.formBuilder.group({
      requestDetail: this.formBuilder.array([])
    });
    this.shared.fUpdateRequestsMassImportObservable().subscribe(
      fRequestsMassImport => {
        console.log('observador mass import request');
        if (fRequestsMassImport && this.dataRequest.clientId === 0) {
            this.invokeFunctionGetClientByRequest();
        } else {
          this.invokeServiceForGetRequestLayoutTemp(this.dataRequest.clientId);
        }
      }
    );

  }

  ngOnInit(): void {
    this.isDelete = false;
    this.invokeFunctionGetClientByRequest();
    this.responseServiceDownloadExcel = 0;
  }

  invokeFunctionGetClientByRequest() {
    this.api.getClientsByRequest()
      .then(
        (response: any) => {
          if (response != null) {
            this.clientList = response;
            this.dataRequest.clientId = response[0].clientId;
            this.invokeServiceForGetRequestLayoutTemp(this.dataRequest.clientId);
          }
        }, error => {
          console.info(error);
        });
  }

  public invokeServiceForGetLayoutMassiveLoadInsured() {
    this.responseServiceDownloadExcel = 1;
    const dataRequest = {
      clientId: this.dataRequest.clientId
    };
    this.api.getLayoutMassiveLoadInsuredAmmia(dataRequest)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', 'LAYOUT_SOLICITUDES_BENEFICIOS.xlsx');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
          this.responseServiceDownloadExcel = 0;
        }, error => {
          this.responseServiceDownloadExcel = 1;
          console.error(error);
        }
      );
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  invokeServiceForGetRequestLayoutTemp(idClient: number) {
    if (idClient !== undefined) {
      const dataRequest = {
        clientId: idClient
      };
      this.api.getRequestTempAmmia(dataRequest).subscribe(
        (response: any) => {
          this.listRequestTemp = response;

        }, error => {
          console.error(error);
        }
      );
    }
  }
  invokeFunctionClear() {
    this.responseServiceDownloadExcel = 0;
  }

  // inicia funciones de detal de solicitud masiva
  seeRequestTempDetail(idRequestTemp) {
    localStorage.removeItem('requestTemp-id');
    localStorage.setItem('requestTemp-id', idRequestTemp);

    localStorage.removeItem('requestTemp-clientId');
    localStorage.setItem('requestTemp-clientId', this.dataRequest.clientId);
    this.shared.fUpdateRequestsMassImportProcess.next(true);
  }

  openModalDeleteRegister( requestTempId) {
    this.idRequestTemp = requestTempId;
    localStorage.removeItem('requestTemp-id');
    localStorage.setItem('requestTemp-id', this.idRequestTemp);
    this.shared.fUpdateRequestsDelete.next(true);
    // aqui vamos a mandar el observador para que pase le id y luego mande a eliminar
  }

  invokeFunctionSaveRequest() {
    console.log('entra a iniciar proceso de lectura excel masivas');
    this.shared.gCurrentCustomerId.next(this.dataRequest.clientId);
    if (this.error.active === true || this.fileUpload === undefined) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
      this.toastr.warning('Debe seleccionar un archivo válido', 'Notificación');
      return;
    }
    console.info(this.fileUpload);
    const formData = new FormData();
    formData.append('file', this.fileUpload);
    this.api.postMassiveLoadLayoutInsured(formData)
      .subscribe(
        response => {
          this.requestTemp = response;
          // this.shared.fUpdateRequests.next(true);
          this.invokeServiceForGetRequestLayoutTemp(this.dataRequest.clientId);
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.fileUpload = undefined;
          // localStorage.removeItem('requestTemp-id');
          // localStorage.setItem('requestTemp-id', this.requestTemp.idRequestTemp);
          // this.invokeServiceForGetRequestLayoutTemp(this.dataRequest.clientId);
          // localStorage.removeItem('requestTemp-clientId');
          // localStorage.setItem('requestTemp-clientId', this.dataRequest.clientId);
          // this.shared.fUpdateRequestsMassImportProcess.next(true);
        }, error => {
          console.error();
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
          this.statsend = false;
        }
      );
  }

}
