<!--<div class="modal fade" id="deleteRegisterRequest" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered justify-content-center modal-sm" >
    <div class="modal-content evento">
      <div class="modal-header evento">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p class="" id="exampleModalLabel">
          ¿ESTÁ SEGURO DE ELIMINAR EL REGISTRO?
        </p>
        <button type="button" class="btn btn-custom  btn-srx"  (click)="deleteRequestTemp()" data-bs-dismiss="modal" aria-label="Close">SÍ</button>
        <button type="button" class="btn btn-custom  btn-srx" data-bs-dismiss="modal" aria-label="Close" >NO</button>
      </div>

    </div>

  </div>
</div>-->


<div class="modal fade" id="deleteRegisterRequest" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content evento">
      <div class="modal-header">
        <h5 class="modal-title">CONFIRMACIÓN</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p style="font-size: small !important; text-align: center">¿ESTÁ SEGURO DE ELIMINAR EL REGISTRO?</p>
      </div>
      <div class="modal-footer" style="display: inline-flex !important; justify-content: space-around;">
        <button type="button" class="btn btn-secondary  .btn-lg" data-bs-dismiss="modal" aria-label="Close">&nbsp; &nbsp; NO&nbsp; &nbsp; </button>
        <button type="button" class="btn btn-primary  .btn-lg" (click)="deleteRequestTemp()" data-bs-dismiss="modal" aria-label="Close">&nbsp; &nbsp; SÍ&nbsp; &nbsp; </button>
      </div>
    </div>
  </div>
</div>
