import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../api.service';
import {SharedService} from '../shared-service.service';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TypeaheadContainerComponent, TypeaheadDirective, TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
declare var $: any;
@Component({
  selector: 'app-admin-ammia-insured',
  templateUrl: './admin-ammia-insured.component.html',
  styleUrls: ['./admin-ammia-insured.component.css']
})
export class AdminAmmiaInsuredComponent implements OnInit {

  insuredPage = 1;
  insuredList: any = [];
  totalInsuredRecords = 0;
  dataFilter: any = {};
  policiesUser: any = [];
  policyDetail: any = {};
  user: any = {};
  attachmentList: any = [];
  incidentDetail: any = {};
  // tslint:disable-next-line:variable-name
  _branchId: number;
  requestDetail: any = {};
  indexPolicy: number;
  idUser: number;
  activePolicy: number;
  modal: number;
  email: string;
  myForm: FormGroup;
  searchItemSelected: string;
  typeaheadLoading: boolean;
  dataSource: Observable<any>;
  itemSelected: any = {};
  constructor(private router: Router,
              private api: ApiService,
              public shared: SharedService,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public fb: FormBuilder) {
    this.myForm = this.fb.group({
      email: ['', [Validators.required]]
    });

    /**
     * Observable para monitorear los cambios en la barra de Búsqueda
     */
    this.dataSource = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.searchItemSelected);
    }).pipe(
      mergeMap((token: string) => this.getStatesAsObservable(token))
    );
    this.itemSelected = {
      id: 0,
      type: 0,
      title: '',
      description: ''
    };
  }

  ngOnInit(): void {
    this.modal = 0;
    this.indexPolicy = 0;
    this._branchId = 1;
    this.invokeFunctionLoadFilter();
    this.invokeServiceForGetInsuredList();

    // tslint:disable-next-line:only-arrow-functions
    $(document).ready(function () {
      const owl = $('#carouselAsegurados2');

      owl.owlCarousel({
        items: 1,
        singleItem: true,
        loop: true,
        autoPlay: true,
        nav: false,
        dots: false,
        autoPlayTimeout: 2000,
        responsive: {
          0: {
            items: 1
          },
        }
      });

      // tslint:disable-next-line:only-arrow-functions
      $('.customNextBtnAsegurados').click(function() {
        owl.trigger('next.owl.carousel');
      });

      // tslint:disable-next-line:only-arrow-functions
      $('.customPreviousBtnAsegurados').click(function() {
        owl.trigger('prev.owl.carousel');
      });
    });

  }

  getStatesAsObservable(token: string): Observable<any> {
    if (token === '') {
      console.log('token vacio');
      this.dataFilter.search = '';
      this.invokeFunctionInsuredFilter();
    }
    return this.api.getFinderResultInsured(token).pipe();
  }
  invokeFunctionLoadFilter() {
    this.dataFilter = {
      page: this.insuredPage,
      size: 4,
      search: ''
    };
  }

  /**
   * Método para obtener una lista de Asegurados
   * la lista se obtiene a través del servicio REST @getInsuredList
   * se indican los parametros de paginado Número de Pagina, Tamaño del pagiinado
   *
   * 1.- Invocamos el servicio rest y recuperamos la respuesta en nuestra variable @insuredList
   */
  invokeServiceForGetInsuredList() {
    console.log('asegurados lista');
    console.log(this.dataFilter);
    this.api.getInsuredAmmia(this.dataFilter)
      .then(
        (response: any) => {
          if (response.total > 0) {
            this.totalInsuredRecords = response.total;
            this.insuredList = this.insuredList.concat(response.insuredList);
          }
        }, error => {
          console.error(error);
        });
  }

  onScrollInsured() {
    if (this.totalInsuredRecords === this.insuredList.length) {
      return;
    }
    this.insuredPage++;
    this.dataFilter.page = this.insuredPage;
    this.invokeServiceForGetInsuredList();
  }

  invokeFunctionInsuredFilter() {
    this.dataFilter.page = 1;
    this.insuredPage = 1;
    this.insuredList = [];
    this.invokeServiceForGetInsuredList();
  }

  invokeFunctionDownloadFileInsured() {
    this.api.getFileInsuredAmmia()
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  'Asegurados.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceGetPoliciesByUser(user: any) {
    this.idUser = user.userId;
    this.user = user;
    const dataObjet = {
      userId: user.userId
    };
    this.api.getPoliciesByUser(dataObjet)
      .subscribe(
        response => {
          this.policiesUser = response;
          console.log(this.policiesUser);
          this.indexPolicy = 0;
          this.invokeServiceGetPolicyDetailByUserPolicy(user.userId, this.policiesUser[0].policyId);
        }, error => {
          console.error(error);
        }
      );
  }
  invokeServiceGetPolicyDetailByUserPolicy(idUser: number, idPolicy: number) {
    this.activePolicy = idPolicy;
    const dataObjet = {
      policyId: idPolicy,
      userId: idUser
    };
    this.api.getPolicyDetailAmmia(dataObjet)
      .subscribe(
        response => {
          this.policyDetail = response;
          console.log(this.policyDetail);
        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceForDownloadPolicyAttachmentFile(attachment) {
    this.api.getPolicyAttachmentFile(attachment.attachmentId)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  attachment.name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

        }, error => {
          console.error(error);
        }
      );
  }

  invokeServiceForDownloadPolicyAttachmentZipFile(policy) {
    this.api.getPolicyAttachmentZip(policy.policyId)
      .subscribe(
        (response: any) => {
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          }
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  `anexos_poliza_${policy.number}.zip`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  invokeFunctionDetailIncident(incident: any) {
    this.invokeServiceGetDetailIncident(incident);
    this.invokeServiceGetAttachmentsByIncident(incident.incidentId);
    this.modal = 2;
  }

  invokeServiceGetDetailIncident(incident: any) {
    console.log(incident);
    const objectData = {
      incidentId: incident.incidentId
    };
    this.api.getIncidentDetailById(objectData)
      .then(
        (response: any) => {
          if (response != null) {
            this.incidentDetail = response;
          }
        }, error => {
          console.info(error);
        });
  }

  invokeServiceGetAttachmentsByIncident(incident: number) {
    const objectData = {
      incidentId: incident
    };
    this.api.getAttachmentsByIncident(objectData)
      .then(
        (response: any) => {
          if (response != null) {
            this.attachmentList = response;
          }
        }, error => {
          console.info(error);
        });
  }

  invokeFunctionCloseModalIncident() {
    const modalShadowElement = document.querySelector('.modal-shadow-evento');
    modalShadowElement.classList.remove('visible');
    this.incidentDetail = [];
  }

  invokeFunctionOpenDetailRequest(r: any) {
    this.invokeFunctionGetDetailRequest(r);
    this.modal = 3;
  }
  invokeFunctionCloseModalUser() {
    this.idUser = 0;
  }

  invokeFunctionGetDetailRequest(request: any) {
    const objectData = {
      requestId: request.requestId,
      requestDetailId: request.requestDetailId
    };
    this.api.getRequestDetailById(objectData)
      .then(
        (response: any) => {
          if (response != null) {
            this.requestDetail = response[0];
          }
        }, error => {
          console.info(error);
        });
  }

  invokeFunctionCloseRequestDetail() {
    const modalShadowElement = document.querySelector('.modal-shadow-evento');
    modalShadowElement.classList.remove('visible');
    this.requestDetail = [];
  }

  public invokeServiceForDownloadCertificateFile() {
    const data = {
      certificateId: this.policyDetail.certificateId
    };
    this.api.getCertificateById(data)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', this.policyDetail.number + '.pdf');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          this.toastr.error('Problemas para procesar la petición, comuniquese con el administrador', 'Notificación');
          console.error(error);
        }
      );
  }

  invokeFunctionPreviousPolicy() {
    console.log('prev-policy');
    if (this.indexPolicy === 0) {
      this.indexPolicy = this.policiesUser.length - 1;
    } else if (this.indexPolicy === (this.policiesUser.length - 1)) {
      this.indexPolicy = 0;
    } else {
      this.indexPolicy = this.indexPolicy - 1;
    }
    this.invokeServiceGetPolicyDetailByUserPolicy(this.idUser, this.policiesUser[this.indexPolicy].policyId, );
  }

  invokeFunctionNextPolicy() {
    console.log('next-policy');
    if (this.indexPolicy === 0) {
      this.indexPolicy = 1;
    } else if (this.indexPolicy === (this.policiesUser.length - 1)) {
      this.indexPolicy = 0;
    } else {
      this.indexPolicy = this.indexPolicy + 1;
    }
    this.invokeServiceGetPolicyDetailByUserPolicy(this.idUser, this.policiesUser[this.indexPolicy].policyId);
  }

  invokeFunctionModal(modal: number) {
    this.modal = modal;
  }

  invokeFunctionSendEmail() {
    if (this.email === '' || this.email === undefined) {
      this.toastr.warning('Debe de colocar un email para enviar el certificado', 'Notificación');
      return;
    }
    const dataObjet = {
      certificateId: this.policyDetail.certificateId,
      email: this.email
    };
    this.api.sharingCertificate(dataObjet)
      .subscribe(
        response => {
          this.toastr.success('Su certificado se compartio de manera correcta.' , ' Notificación');
          this.modal = 0;
        }, error => {
          console.error(error);
        }
      );
  }
  public invokeServiceForDownloadFileAttachment(attachment: any)  {
    const objData = {
      attachmentId: attachment.attachmentId
    };
    this.api.getAttachmentIncidentById(objData)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download',  attachment.name);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }, error => {
          console.error(error);
        }
      );
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.itemSelected = e.item;
    console.log(this.itemSelected);
    this.dataFilter.search = this.itemSelected.id;
    this.invokeFunctionInsuredFilter();
  }

}
