<div>
<form action="">
  <!--- Buscar cliente-->
  <div class="form-row">
    <div class="form-group">
      <label >Razón Social</label>
      <select class="form-select ammiaAdmin-style  mb-3" [(ngModel)]="dataRequest.clientId" (change)="invokeServiceForGetRequestLayoutTemp(dataRequest.clientId)"
              name="clientId" required >
        <option *ngFor="let item of clientList" [value]="item.clientId"
        >{{item.name}}</option>
      </select>
    </div>
  </div>


  <!-- SEPARADOR -->
  <div class="separador-tarjetas">
    <h4>Descarga la plantilla</h4>
    <hr>
  </div>

  <div>
    <p class="solicitud-parrafo">Usa la plantilla de Excel para ingresar en una sola solicitud varias altas o bajas de asegurados de una póliza maestra.</p>
    <!--DESCARGAR Plantilla de alta o baja multiple -->
    <div class="reconocimiento-antiguedad " id="reconocimientoAntiguedadCargado">
      <div class="d-center gap-8">
        <img src="../../assets/img/ammia/iconos/icono_cargadocumento-gris.svg" alt="">
        <div>
          <h5>Plantilla para alta y/o baja múltiple</h5>
          <p>LAYOUT_SOLICITUDES_BENEFICIOS.xlsx</p>
        </div>

      </div>
      <div class="" *ngIf="responseServiceDownloadExcel == 0">
        <button type="button"  href="" class="enlace"  (click)="invokeServiceForGetLayoutMassiveLoadInsured()">
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.7 9.6C15.4878 9.6 15.2843 9.68429 15.1343 9.83432C14.9843 9.98434 14.9 10.1878 14.9 10.4V13.6C14.9 13.8122 14.8157 14.0157 14.6657 14.1657C14.5157 14.3157 14.3122 14.4 14.1 14.4H2.9C2.68783 14.4 2.48434 14.3157 2.33431 14.1657C2.18429 14.0157 2.1 13.8122 2.1 13.6V10.4C2.1 10.1878 2.01571 9.98434 1.86569 9.83432C1.71566 9.68429 1.51217 9.6 1.3 9.6C1.08783 9.6 0.884344 9.68429 0.734315 9.83432C0.584285 9.98434 0.5 10.1878 0.5 10.4V13.6C0.5 14.2365 0.752856 14.847 1.20294 15.2971C1.65303 15.7471 2.26348 16 2.9 16H14.1C14.7365 16 15.347 15.7471 15.7971 15.2971C16.2471 14.847 16.5 14.2365 16.5 13.6V10.4C16.5 10.1878 16.4157 9.98434 16.2657 9.83432C16.1157 9.68429 15.9122 9.6 15.7 9.6ZM7.932 10.968C8.00808 11.0408 8.0978 11.0979 8.196 11.136C8.29176 11.1783 8.3953 11.2002 8.5 11.2002C8.6047 11.2002 8.70824 11.1783 8.804 11.136C8.9022 11.0979 8.99192 11.0408 9.068 10.968L12.268 7.768C12.4186 7.61736 12.5033 7.41304 12.5033 7.2C12.5033 6.98696 12.4186 6.78264 12.268 6.632C12.1174 6.48136 11.913 6.39673 11.7 6.39673C11.487 6.39673 11.2826 6.48136 11.132 6.632L9.3 8.472V0.8C9.3 0.587827 9.21572 0.384344 9.06569 0.234315C8.91566 0.0842855 8.71217 0 8.5 0C8.28783 0 8.08434 0.0842855 7.93431 0.234315C7.78429 0.384344 7.7 0.587827 7.7 0.8V8.472L5.868 6.632C5.79341 6.55741 5.70486 6.49824 5.6074 6.45787C5.50994 6.4175 5.40549 6.39673 5.3 6.39673C5.19451 6.39673 5.09006 6.4175 4.9926 6.45787C4.89514 6.49824 4.80659 6.55741 4.732 6.632C4.65741 6.70659 4.59824 6.79514 4.55787 6.8926C4.5175 6.99006 4.49673 7.09451 4.49673 7.2C4.49673 7.30549 4.5175 7.40994 4.55787 7.5074C4.59824 7.60486 4.65741 7.69341 4.732 7.768L7.932 10.968Z" />
          </svg>
          Descargar documento
        </button>

      </div>
      <div *ngIf="responseServiceDownloadExcel == 1">
        <div class="w-100 d-column-center">
          <a href="javascript:;"  aria-controls="offcanvasRight" >
            <dotlottie-player src="https://lottie.host/06fc7b75-f5d2-4524-86f8-fbbe96bf456a/VGiGPUrFSr.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
          </a>
        </div>
      </div>
      <div  *ngIf="responseServiceDownloadExcel == 2">
        <a href="javascript:;"   aria-controls="offcanvasRight" >
          <dotlottie-player src="https://lottie.host/4c9dabcd-e6c6-41c2-84c9-7cf1b79890af/yn2rFJ1ICK.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
        </a>
        <div>
          <p class="solicitud-parrafo">Se ha producido un error al descargar plantilla, por favor contacta al administrador.
          </p>
        </div>
        <div class="d-center w-100 mt-5">
          <button class="btn primario" id="btnGenerarSolicitudVehiculocc" type="button" data-bs-dismiss="offcanvas" aria-label="Close" (click)="invokeFunctionClear()">
            <img src="../../assets/img/ammia/iconos/check-azuld2.svg" alt="" class="icono-sm">
            Cerrar
          </button>
        </div>
      </div>

    </div>
  </div>

  <!-- SEPARADOR -->
  <div class="separador-tarjetas">
    <h4>Sube la plantilla</h4>
    <hr>
  </div>

  <p class="solicitud-parrafo">Llena la plantilla con los datos de los asegurados y súbela aquí para hacer la solicitud de altas o bajas de una póliza maestra.</p>

  <!-- SUBIR Plantilla de alta o baja multiple -->
  <div class="reconocimiento-antiguedad" id="subirPlantillaAlta">
    <div class="d-center gap-8">
      <img src="../../assets/img/ammia/iconos/icono_cargadocumento-gris.svg" alt="">
      <div>
        <h5>Plantilla para alta y/o baja múltiple</h5>
        <div class="form-group">
          <label class="text-primary" *ngIf="fileUpload == undefined" [hidden]="error.active">----------</label>
          <label class="text-primary" style="width: 100%" *ngIf="fileUpload != undefined" [hidden]="error.active">
            {{ fileUpload.name }} <span class="pull-right">{{shared.formatBytes(fileUpload.size,2)}}</span>
          </label>
          <label class="text-danger" [hidden]="!error.active">{{ error.msg }}</label>
        </div>
      </div>

    </div>
    <div class="">
      <label class="enlace" >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6ZM0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8ZM8 4C8.21217 4 8.41566 4.08429 8.56569 4.23431C8.71571 4.38434 8.8 4.58783 8.8 4.8V7.2H11.2C11.4122 7.2 11.6157 7.28429 11.7657 7.43431C11.9157 7.58434 12 7.78783 12 8C12 8.21217 11.9157 8.41566 11.7657 8.56569C11.6157 8.71571 11.4122 8.8 11.2 8.8H8.8V11.2C8.8 11.4122 8.71571 11.6157 8.56569 11.7657C8.41566 11.9157 8.21217 12 8 12C7.78783 12 7.58434 11.9157 7.43431 11.7657C7.28429 11.6157 7.2 11.4122 7.2 11.2V8.8H4.8C4.58783 8.8 4.38434 8.71571 4.23431 8.56569C4.08429 8.41566 4 8.21217 4 8C4 7.78783 4.08429 7.58434 4.23431 7.43431C4.38434 7.28429 4.58783 7.2 4.8 7.2H7.2V4.8C7.2 4.58783 7.28429 4.38434 7.43431 4.23431C7.58434 4.08429 7.78783 4 8 4Z"/>
        </svg>
        <input type="file" style="display: none" (change)="onChangeInputFile($event)" name="antiquie">
        Subir documento
      </label>

    </div>


  </div>
  <!-- REEMPLAZAR Plantilla de alta o baja multiple -->
  <div class="reconocimiento-antiguedad d-none" id="editarPlantillaAlta">
    <div class="d-center gap-8">
      <img src="../../assets/img/ammia/iconos/icono_cargadocumento-gris.svg" alt="">
      <div>
        <h5>Plantilla para alta y/o baja múltiple</h5>
        <p>documento_cargado.pdf</p>
      </div>

    </div>
    <div class="">
      <button type="button"  href="" class="enlace"  >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.656353 1.77101C1.07661 1.35076 1.6466 1.11466 2.24093 1.11466H7.44267C7.85949 1.11466 8.19739 1.45256 8.19739 1.86938C8.19739 2.28619 7.85949 2.62409 7.44267 2.62409H2.24093C2.04692 2.62409 1.86087 2.70116 1.72368 2.83834C1.5865 2.97552 1.50943 3.16158 1.50943 3.35559V13.7591C1.50943 13.9531 1.5865 14.1391 1.72368 14.2763C1.86087 14.4135 2.04692 14.4906 2.24093 14.4906H12.6444C12.8384 14.4906 13.0245 14.4135 13.1617 14.2763C13.2988 14.1391 13.3759 13.9531 13.3759 13.7591V8.55733C13.3759 8.14051 13.7138 7.80261 14.1306 7.80261C14.5474 7.80261 14.8853 8.14051 14.8853 8.55733V13.7591C14.8853 14.3534 14.6492 14.9234 14.229 15.3436C13.8087 15.7639 13.2387 16 12.6444 16H2.24093C1.6466 16 1.07661 15.7639 0.656353 15.3436C0.236097 14.9234 0 14.3534 0 13.7591V3.35559C0 2.76126 0.236097 2.19127 0.656353 1.77101Z" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7383 1.50943C13.5388 1.50943 13.3474 1.58869 13.2063 1.72977L6.60525 8.33086L6.25061 9.74939L7.66914 9.39475L14.2702 2.79367C14.4113 2.65259 14.4906 2.46124 14.4906 2.26172C14.4906 2.06221 14.4113 1.87086 14.2702 1.72977C14.1291 1.58869 13.9378 1.50943 13.7383 1.50943ZM12.139 0.662444C12.5631 0.238288 13.1384 0 13.7383 0C14.3381 0 14.9134 0.238288 15.3376 0.662444C15.7617 1.0866 16 1.66188 16 2.26172C16 2.86157 15.7617 3.43685 15.3376 3.86101L8.58866 10.6099C8.49194 10.7066 8.37074 10.7752 8.23804 10.8084L5.3964 11.5188C5.13921 11.5831 4.86714 11.5078 4.67969 11.3203C4.49223 11.1329 4.41687 10.8608 4.48117 10.6036L5.19158 7.76196C5.22476 7.62926 5.29337 7.50806 5.3901 7.41134L12.139 0.662444Z" />
        </svg>

        Reemplazar documento
      </button>

    </div>


  </div>

  <!---historico de solicitude precrgadas-->
  <div class="separador-tarjetas">
    <h4>Tus solicitudes precargadas</h4>
    <hr>
  </div>
  <div id="benefits" class="table-container">
    <table class="table  table-borderless h-in table-fixed-header" style="width: 100%">
      <thead>
      <th scope="col"><h4>Fecha creación</h4></th>
      <th colspan="2" scope="col"><h4></h4></th>
      </thead>
      <tbody>
      <tr *ngFor="let reqTemp of listRequestTemp" class="asegurados-beneficios-card" >
        <td>
          <div class="d-column-start ">
            <h5>{{reqTemp.fechaRequestCreated | date: 'dd/MM/yyyy HH:mm:ss'}}</h5>
          </div>
        </td>
        <td>
          <div class="d-column-start ">
            <button type="button" class="btn btn-xs btn-custom pull-right " title="VER DETALLES" (click)="seeRequestTempDetail(reqTemp.idRequestTemp)"
                    data-bs-toggle="modal" data-bs-target="#altaMultipleValidacionModificarDatos">
              <i class="bi bi-eye"></i>
            </button>
          </div>
        </td>
        <td>
          <div class="d-column-start ">
            <button type="button" class="btn btn-xs btn-custom pull-right " title="ELIMINAR"
                    data-bs-toggle="modal" data-bs-target="#deleteRegisterRequest"
                    (click)="openModalDeleteRegister(reqTemp.idRequestTemp)">
              <i class="bi bi-eraser-fill"></i>
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class=" offcanvas-footer d-center mt-3">
    <button id="btnGenerarSolicitudGeneral" type="button" [ngClass]="fileUpload == undefined ? 'btn deshabilitado' : 'btn primario'"
            (click)="invokeFunctionSaveRequest()">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.5237 6.98805L10.2319 14.3279L7.52681 11.5317L5.99787 13.0463L9.17336 16.3084C9.29097 16.4249 9.52619 16.5415 9.76142 16.658C9.99664 16.7745 10.2319 16.7745 10.3495 16.7745C10.4671 16.7745 10.8199 16.7745 10.9375 16.658C11.1727 16.5415 11.2904 16.4249 11.5256 16.3084L19.1703 8.50262L17.5237 6.98805Z" />
        <path d="M20.5819 3.49515C18.3473 1.28155 15.2894 0 11.9963 0C8.82081 0 5.76293 1.28155 3.52833 3.49515C1.29372 5.82524 0 8.73786 0 12C0 15.1456 1.29372 18.1748 3.52833 20.5049C5.76293 22.7184 8.82081 24 11.9963 24C15.1718 24 18.2297 22.7184 20.4643 20.5049C22.6989 18.2913 23.9926 15.2621 23.9926 12C24.1102 8.73786 22.8165 5.82524 20.5819 3.49515ZM12.1139 21.6699C6.70382 21.6699 2.35222 17.2427 2.35222 11.8835C2.35222 6.52427 6.70382 2.09709 12.1139 2.09709C17.524 2.09709 21.8756 6.52427 21.8756 11.8835C21.8756 17.3592 17.524 21.6699 12.1139 21.6699Z" />
      </svg>

      Iniciar proceso
    </button>
  </div>



</form>
</div>






